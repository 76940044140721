<template lang='pug'>
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mb-5.lh-18.privacy
		h1.mb-6 {{ $t('privacy.title') }}
		p.text-muted.mb-4 {{ $t('date.published') }}: {{ updatedDate }}

		p.mb-4(v-html="$t('privacy.head')")

		hr.primary.my-6

		slot(v-for='(c,index) in common')
			h2.mb-6 {{index + 1}}. {{ c.title }}
			p(v-if='c.desc') {{ c.desc }}
			ol.ol-deep.ol-deep-parent.mb-4(:style="`--n: '${index+1}`")
				li(v-for='i in c.ul' :key='i.text' :class='{"d-block" : i.table || i.ulDeepSimple}')
					div(v-if='i.ulDeep')
						span(v-html='i.text')
						ol.ol-deep.mt-2(:style="`--n: '${index+1}`" v-if='i.ulDeep')
							.vuetable(v-if='i.ulDeep[0].table')
								.table-responsive
									table.my-4.table.b-table.table-bordered
										thead
											tr
												td.f-1 {{ i.ulDeep[0].table.th[0] }}
												td.f-1 {{ i.ulDeep[0].table.th[1] }}
										tbody
											tr(v-for='tr in i.ulDeep[0].table.tr')
												td(v-for='td in tr.td' v-html='td')
							li(v-else v-for='d in i.ulDeep' :key='d.text')
								div(v-html='d.text')
					span(v-else-if='i.title')
						b {{ i.title }} –&nbsp;
						span(v-html='i.text')
					span(v-else v-html='i.text')
					table.my-4.table.b-table.table-bordered(v-if='i.table')
						tbody
							tr(v-for='tr in i.table.tr')
								td(v-for='td in tr')
									slot(v-for='tdi in td')
										p.mb-1(v-for='tdii in tdi') {{ tdii }}
					div.my-4(v-if='i.ulDeepSimple')
						p.mb-2(v-for='ii in i.ulDeepSimple' :key='ii.text')
							span(v-html='ii.text')

			ul.mb-4.list-unstyled(v-if='c.ulSimple')
				li.mb-2(v-for='i in c.ulSimple' :key='i.text' :class='{"d-block" : i.table}')
					span(v-if='i.title')
						b {{ i.title }} –&nbsp;
						span(v-html='i.text')
					span(v-else v-html='i.text')
					div.mb-4(v-if='i.sub')
						hr.primary
						span.text-small(v-html='i.sub')
			hr.primary.my-6(v-if='index != common.length-1')
		pw-btn-up
		modal-cookie
</template>

<script>
import CompanyData from '@/components/Common/Company'
import PwBtnUp from '@/components/Buttons/PwBtnUp'
import ModalCookie from '@/components/Modals/Cookie'
import moment from 'moment'
import {bus} from '@/main'

export default {
	metaInfo () { return {title: bus.$t('privacy.title')}},
	components: {
		CompanyData,
		PwBtnUp,
		ModalCookie
	},
	data() {
		let date = '2023-02-02';
		let day = moment(date).format('DD');

		return {
			updatedDate	: moment(date).format('DD MMMM YYYY').replace(day, `«${day}»`),
		}
	},
	computed: {
		common () {
			let data = _.cloneDeep(bus.$t('privacy.common'));

			_.each(data, (m, i) => {
				_.each(data[i].ul, (u, iu) => {
					data[i].ul[iu].text = data[i].ul[iu].text.replace(/\$\{bus\.botPayName\}/, bus.botPayName);
					data[i].ul[iu].text = data[i].ul[iu].text.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName);
					data[i].ul[iu].text = data[i].ul[iu].text.replace(/\$\{bus\.botSupport\}/, bus.botSupport);

					_.each(data[i].ul[iu].ulDeep, (d, id) => {
						if ( data[i].ul[iu].ulDeep[id].text ) {
							data[i].ul[iu].ulDeep[id].text = data[i].ul[iu].ulDeep[id].text.replace(/\$\{bus\.botPayName\}/, bus.botPayName);
							data[i].ul[iu].ulDeep[id].text = data[i].ul[iu].ulDeep[id].text.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName);
							data[i].ul[iu].ulDeep[id].text = data[i].ul[iu].ulDeep[id].text.replace(/\$\{bus\.botSupport\}/, bus.botSupport);
						}
					})
				})
			})

			return data;
		}
	},
	created () {
		window.scrollTo(0,0);
	}
}
</script>

<style lang="scss" scoped>
.privacy {
	margin-top: 76px;

	@media(max-width: 767px) {
		margin-top: 20px;
	}
}
</style>
